import React, {useCallback, useState} from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import {useAppSelector} from "../../hooks";
import Pin from "../../assets/img/pickup.svg"

const containerStyle = {
    width: '100%',
    height: '280px',
    marginBottom: '20px',
    marginTop: '15px'
};

const center = {
    lat: 49.76938123073586, // 49.99215377036081,
    lng: 30.13918113617745 //36.23242168419331
};

const Map: React.FC<{ address: string}> = ({address}) => {
    const currentLang = useAppSelector<string>((state) => state.currentLang.value);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
        libraries: ['geocoding'],
        language: 'pl'
    })


    const [map, setMap] = React.useState(null)

    const onLoad = useCallback(function callback(map: any) {
        const geocoder = new window.google.maps.Geocoder();
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        geocoder
            .geocode({ address })
            .then((result: any) => {
                const { results } = result;

                if (map) {
                    map.setCenter(results[0].geometry.location);
                    map.setZoom(16);
                    const m = new google.maps.Marker({icon: Pin});
                    m.setPosition(results[0].geometry.location);
                    setMap(map);
                    m.setMap(map);
                }
            })
            .catch((e: string) => {
                alert("Geocode was not successful for the following reason: " + e);
            });

    }, [])

    const onUnmount = useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={6}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            { /* Child components, such as markers, info windows, etc. */ }
            <></>
        </GoogleMap>
    ) : <></>
}

export default React.memo(Map)