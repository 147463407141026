import BaseHttpHandler from "./baseHttpHandler";
import ApiResponseModel from "../dto/ApiResponseModel";

export default class ApiHttpHandler extends BaseHttpHandler {

    constructor(){
        super(process.env.REACT_APP_API_URL!, true, true) //http://3.77.214.187/
    }

    public fetchData(url: string, method: string, body?: string): Promise<any>{
        return this.doRequest(url, method, body).then(data => {
           return (data as ApiResponseModel).data;
        });
    }
}