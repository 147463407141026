import pl from "date-fns/locale/pl";
import uk from "date-fns/locale/uk";

function calendarByLanguage (lang: string) {
    if (lang === 'pl'){
    return {
        sunday: 'Nd',
        monday: 'Pn',
        tuesday: 'Wt',
        wednesday: 'Śr',
        thursday: 'Cz',
        friday: 'Pt',
        saturday: 'Sb',
        ok: 'OK',
        today: 'Dziś',
        yesterday: 'Wczoraj',
        hours: 'Godziny',
        minutes: 'Minuty',
        seconds: 'Sekundy',

        formattedMonthPattern: 'MMM yyyy',
        formattedDayPattern: 'dd mm rr',
        dateLocale: pl
    }
}
else {
        return {
            sunday: 'Нд',
            monday: 'Пн',
            tuesday: 'Вт',
            wednesday: 'Ср',
            thursday: 'Чт',
            friday: 'Пт',
            saturday: 'Сб',
            ok: 'OK',
            today: 'Сьогодні',
            yesterday: 'Вчора',
            hours: 'Години',
            minutes: 'Хвилин',
            seconds: 'Секунд',

            formattedMonthPattern: 'MMM yyyy',
            formattedDayPattern: 'дд мм рр',
            dateLocale: uk
        }
    }
}

export default calendarByLanguage;