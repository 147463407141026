import React, {useEffect, useState} from "react";
import Header from "../../blocks/Header/Header";
import Footer from "../../blocks/Footer";
import {useAppDispatch, useAppSelector} from "../../hooks";

import {CartOrder} from "../../interfaces/CartProduct";

import OrderProducts from "../../components/OrderProducts/OrderProducts";
import {addOrder, clearOrders, removeOrder, setOrders} from "../../slices/orderSlice";


import AddOrderModel from "../../dto/order/AddOrderModel";
import {Button, Col, Grid, Row} from "rsuite";
import {useTranslation} from "react-i18next";


import orderService from "../../services/orderService";
import {getCartAsync} from "../../slices/actions/cartAsyncActions";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import User from "../../interfaces/User";
import TotalOrder from "../../components/TotalOrder/TotalOrder";
import GetOrderModel from "../../dto/order/GetOrderModel";
import DeliverySavedAddress
    from "../../components/OrderProducts/OrderProductsDelivery/delivery/DeliverySavedAddress/DeliverySavedAddress";
import CartOrderProducts from "../../components/CartOrderProducts/CartOrderProducts";
import {CarrierType} from "../../enums/CarrierType";

import "./OrderPage.sass"
import {DeliveryPayer} from "../../enums/DeliveryPayer";
import {PreOrderResponse} from "../../dto/order/PreOrderResponseWrapper";
import OrderFinished from "./OrderFinished/OrderFinished";
import {clearReserve} from "../../slices/reserveSlice";


const ClearOrder: React.FC = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(clearOrders())
    }, [])
    return (
        <div></div>
    )
}

const OrderPage: React.FC<{isReserve?: boolean, isCopy?: boolean}> = ({isReserve = false, isCopy = false}) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    let currentLang: string = useAppSelector((state) => state.currentLang.value);
    const cartOrders = useAppSelector<CartOrder[]>((state) => state.cart.value);
    const reserve = useAppSelector<CartOrder>((state) => state.reserve.value);
    const user = useAppSelector<User>(state => state.user.value)
    const [orders, setPageOrders] = useState<AddOrderModel[]>([]);
    const selectedOrders = useAppSelector<AddOrderModel[]>(state => state.order.value)
    const [isOrderFinished, setIsOrderFinished] = useState(false)
    let {orderId} = useParams();

    const [preOrderResult, setPreOrderResult] = useState<PreOrderResponse[]>([])

    useEffect(() => {
        if(isOrderFinished) return;
        if (!user) return;
        if(isCopy){
            if(orderId) {
                orderService.getOrder(+orderId)
                    .then(data => {
                        const order: AddOrderModel = {
                            "cartOrderId": 0,
                            "id": 0,
                            "tempId": 0,
                            "contactName": data.contactName,
                            "contactEmail": data.contactEmail,
                            "contactPhone": data.contactPhone,
                            "comment": data.comment,
                            "isReserve": false,
                            "deliveryPayer": data.deliveryPayer,
                            "cashOnDeliveryAmount": data.cashOnDeliveryAmount,
                            "isPickup": data.isPickup,
                            "carModel": data.carModel,
                            "carNumber": data.carNumber,
                            "pickupDate": data.pickupDate,
                            "pickupTime": data.pickupTime,
                            "deliveryAddressId": data.deliveryAddress.id,
                            "deliveryAddress": data.deliveryAddress,
                            "receiverId": data.receiver?.id,
                            "receiver": null,
                            "isUserReceiver": data.isUserReceiver,
                            "weight": data.weight,
                            "useNPShippingName": false,
                            "usePaymentControl": false,
                            "items": data.items.map((item, idx) => {
                                return {
                                    "productId": item.productId,
                                    "product": {
                                        id: item.productId,
                                        vendorCode: item.vendorCode,
                                        photoUrl: item.photoUrl,
                                        name: item.productName,
                                        lastPurchaseDate: item.lastBoughtOn,
                                        available: item.left,
                                        recommendedRetailPrice: item.recommendedRetailPrice,
                                        price: item.price,
                                        lastPurchaseCount: item.lastBoughtCount,
                                        categoryId: 0
                                    },
                                    "count": item.count
                                }
                            })
                        };
                        /*orderService.preOrder(order.items.map(i => i.productId)).then(data => {
                            if(data.orders.length > 0) {
                                order.id = data.orders[0].orderId;
                            }
                            setPreOrderResult(data.orders)
                        })*/
                        dispatch(setOrders([order]));
                        setPageOrders([order]);
                    });
            }
        }
        else if(isReserve) {
            orderService.addReserve({items: reserve.orderItems.map(i => {return {productId: i.product.id, count: i.count};})})
                .then(data => {
                    setPreOrderResult([data])
                    const order: AddOrderModel = {
                        "cartOrderId": 0,
                        "id": data.orderId,
                        "tempId": data.orderId,
                        "contactName": user.firstName + ' ' + user.lastName,
                        "contactEmail": user.email,
                        "contactPhone": user.phone,
                        "comment": "",
                        "isReserve": true,
                        "deliveryPayer": 1,
                        "cashOnDeliveryAmount": null,
                        "isPickup": false,
                        "carModel": '',
                        "carNumber": '',
                        "pickupDate": '',
                        "pickupTime": '',
                        "deliveryAddressId": null,
                        "deliveryAddress": null,
                        "receiverId": user.id,
                        "receiver": null,
                        "isUserReceiver": true,
                        "useNPShippingName": false,
                        "usePaymentControl": false,
                        "weight": data.weight,
                        "items": reserve.orderItems.map((item, idx) => {
                            return {
                                "productId": item.product.id,
                                "product": item.product,
                                "count": item.count
                            }
                        })
                    }
                    dispatch(setOrders([order]));
                    setPageOrders([order]);
                });
        } else {
            if (orderId) {
                orderService.getOrder(+orderId)
                    .then(data => {
                        const order: AddOrderModel = {
                            "cartOrderId": 0,
                            "id": data.id,
                            "tempId": data.id,
                            "contactName": data.contactName,
                            "contactEmail": data.contactEmail,
                            "contactPhone": data.contactPhone,
                            "comment": data.comment,
                            "isReserve": false,
                            "deliveryPayer": data.deliveryPayer,
                            "cashOnDeliveryAmount": data.cashOnDeliveryAmount,
                            "carModel": data.carModel,
                            "carNumber": data.carNumber,
                            "isPickup": data.isPickup,
                            "pickupDate": data.pickupDate,
                            "pickupTime": data.pickupTime,
                            "deliveryAddressId": data.deliveryAddress?.id,
                            "deliveryAddress": data.deliveryAddress,
                            "receiverId": data.isUserReceiver? null: data.receiver?.id,
                            "receiver": data.receiver,
                            "isUserReceiver": data.isUserReceiver,
                            "useNPShippingName": false,
                            "usePaymentControl": false,
                            "weight": data.weight,
                            "items": data.items.map((item, idx) => {
                                return {
                                    "productId": item.productId,
                                    "product": {
                                        id: item.productId,
                                        vendorCode: item.vendorCode,
                                        photoUrl: item.photoUrl,
                                        name: item.productName,
                                        lastPurchaseDate: item.lastBoughtOn,
                                        available: item.left,
                                        recommendedRetailPrice: item.recommendedRetailPrice,
                                        price: item.price,
                                        lastPurchaseCount: item.lastBoughtCount,
                                        categoryId: 0
                                    },
                                    "count": item.count
                                }
                            })
                        };
                        dispatch(setOrders([order]));
                        setPageOrders([order]);
                    });
            } else {
                let isAnyProdSelected = false;
                cartOrders.forEach(o => {
                    if (o.orderItems.length > 0) {
                        isAnyProdSelected = true;
                    }
                })
                if (!cartOrders || !isAnyProdSelected) {
                    return;
                }
                orderService.preOrder(cartOrders.filter(o => o.orderItems.length > 0).map(o => o.orderId))
                    .then(resp => {

                        setPreOrderResult(resp.orders)

                        let counter = 0;
                        let newOrders: AddOrderModel[] = cartOrders.filter(order => order.orderItems.length != 0)
                            .map(order => {
                            let respItem = resp.orders.find(i => i.cartOrderId == order.orderId);
                            counter++;
                            return {
                                "cartOrderId": order.orderId,
                                "id": respItem!.orderId?? 0,
                                "tempId": counter,
                                "contactName": user.firstName + ' ' + user.lastName,
                                "contactEmail": user.email,
                                "contactPhone": user.phone,
                                "comment": "",
                                "isReserve": false,
                                "deliveryPayer": 1,
                                "cashOnDeliveryAmount": null,
                                "isPickup": false,
                                "carModel": '',
                                "carNumber": '',
                                "pickupDate": '',
                                "pickupTime": '',
                                "deliveryAddressId": null,
                                "deliveryAddress": null,
                                "receiverId": user.id,
                                "receiver": null,
                                "isUserReceiver": true,
                                "useNPShippingName": false,
                                "usePaymentControl": false,
                                "weight": respItem!.weight,
                                "items": order.orderItems.map((item, idx) => {
                                    return {
                                        "productId": item.product.id,
                                        "product": item.product,
                                        "count": item.count
                                    }
                                })
                            }
                        });
                        dispatch(setOrders(newOrders))
                        setPageOrders(newOrders);
                    });
            }
        }
    }, [cartOrders, user, currentLang])

    const [finishedOrders, setFinishedOrders] = useState<GetOrderModel[]>([])
    const [errorsStateShipping, setErrorsStateShipping] = useState<number[]>([])
    const [errorsStaterReceiver, setErrorsStaterReceiver] = useState<number[]>([])

    const [isFinishedOrdersWithFail, setIsFinishedOrdersWithFail] = useState(false)

    const createOrders = () => {

        setErrorsStaterReceiver([])

        let errorsDelivery: number[] = []
        let errorReceiver: number[] = []
        selectedOrders.map((item) => {
            if (item.deliveryAddress == null && item.deliveryAddressId == null && !item.isPickup){
                errorsDelivery.push(item.cartOrderId)
            }

            if (item.receiverId == null && item.receiver == null && !item.isUserReceiver) {
                errorReceiver.push(item.cartOrderId)
            }
        })

        setErrorsStateShipping([...errorsDelivery])
        setErrorsStaterReceiver([...errorReceiver])


        if(errorsDelivery.length == 0 && errorReceiver.length == 0) {
            orderService.addOrders(selectedOrders).then(data => {

                if(data.failedOrders.length == 0){
                    dispatch(clearOrders());
                    dispatch(getCartAsync());
                    setFinishedOrders(data.createdOrders)
                    setIsOrderFinished(true)
                    if (isReserve) {
                        dispatch(clearReserve());
                    }
                }
                else {

                    const ordersId = data.failedOrders.map(item => item.cartOrderId)

                    const ordersFailed = orders.filter(item => ordersId.indexOf(item.cartOrderId) != -1)

                    setPageOrders(ordersFailed)
                    dispatch(setOrders(ordersFailed))
                    setPreOrderResult(data.failedOrders)
                    setFinishedOrders(data.createdOrders)

                    setIsOrderFinished(false)

                    setIsFinishedOrdersWithFail(true)

                }



            })
        }

    }

    const deleteErrorReceiver = (orderId: number) => {

       let index = errorsStaterReceiver.indexOf(orderId)
        let shadowState = [...errorsStaterReceiver]

        shadowState.splice(index, 1)

        setErrorsStaterReceiver(shadowState)
    }

    const deleteErrorShipping = (orderId: number) => {

        let index = errorsStateShipping.indexOf(orderId)
        let shadowState = [...errorsStateShipping]

        shadowState.splice(index, 1)

        setErrorsStateShipping(shadowState)
    }


    const productsCart = useAppSelector<CartOrder[]>((state) => state.cart.value);
    const activeOrder = useAppSelector<number>(state => state.activeOrder.value)

    let totalProducts = 0;

    if (productsCart.length != 0) {
        productsCart[activeOrder].orderItems.forEach((product) => {
            totalProducts += product.count
        })
    }
    const [productsCount, setProductsCount] = useState(totalProducts)

    return (
        <div className="order-page-wrapper">
            <Header></Header>
            <main>
                {isOrderFinished ? <div className="order-finished-wrapper">
                        {finishedOrders.map((order, index) => {
                            return (
                                <OrderFinished key={index} order={order} user={user} productsCount={productsCount} />
                            )

                        })}

                </div> : <div>
                    {orders.length !== 0 ? orders.filter(order => order.items.length != 0).map((order, index) => {
                        let orderResult = preOrderResult.find(item => item.cartOrderId == order.cartOrderId
                            || (item.orderId != null && item.orderId == order.tempId))
                        let hasErrors = errorsStateShipping.indexOf(order.cartOrderId)
                        let hasErrorReceiver = errorsStaterReceiver.indexOf(order.cartOrderId)

                        return (
                            <OrderProducts
                                order={order}
                                key={index}
                                numberOrder={index}
                                preOrderResult={orderResult ? orderResult : null}
                                errorsStateShipping={hasErrors == -1}
                                errorStateReceiver={hasErrorReceiver == -1}
                                deleteErrorReceiver={deleteErrorReceiver}
                                deleteErrorShipping={deleteErrorShipping}
                                isReserve={isReserve}
                            />
                        )

                    }) : null}

                    <div className="send-order-wrapper">
                        <Button
                            onClick={createOrders}
                            className="btn btn-lg"
                            disabled={selectedOrders.length != 0 ? false : true}
                        >
                            {isReserve ? t('basket.checkout_reserve') : <span>
                                {t('order.finishOrder')}&nbsp;
                                ({selectedOrders.length})
                            </span>}
                        </Button>
                    </div>

                    <div className="order-finished-wrapper">
                    {isFinishedOrdersWithFail ?
                        finishedOrders.map((order, index) => {
                                return (
                                    <OrderFinished key={index} order={order} user={user} productsCount={productsCount} />
                                )

                            }): null}
                    </div>


                </div>}


            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default OrderPage;