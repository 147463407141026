import React, {useEffect, useState} from "react";


import NoImage from '../../assets/img/no-img_small.svg';
import "./TableOrder.sass"
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";
import {ProductEntity} from "../../interfaces/Product/ProductEntity";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import ProductCountTable from "../ProductCountTable/ProductCountTable";
import AddToComare from "../AddToComare/AddToComare";
import {Col, FlexboxGrid, Row} from "rsuite";
import GenericButton from "../Buttons/GenericButton";
import ProductCountSum from "../ProductCountSum/ProductCountSum";
import {useAppSelector} from "../../hooks";
import ProductAvailable from "../ProductAvailable/ProductAvailable";

const columnHelper = createColumnHelper<ProductEntity>();

const TableOrderEdit:React.FC<{data: ProductEntity[]}> = ({data}) => {

    const { t } = useTranslation();
    const wsRate = useAppSelector<number>((state) => state.wsRate.value)
    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)
    const [isMobileView, setIsMobileView] = useState(false)

    useEffect(() => {
        if(deviceWidth <= 1024) {
            setIsMobileView(true)
        }
        else {
            setIsMobileView(false)
        }

    }, [deviceWidth])

    useEffect(() => {
        const handleWindowResize = () => setDeviceWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [])

    const deleteItem = (productOrderId: number) => {

    }

    const columnsCell = [
        columnHelper.accessor('vendorCode', {
            cell: info => <span>{info.getValue()}</span>,
            header: () => <span>{t('productsTableHeader.vendorCode')}</span>,
        }),
        columnHelper.accessor(row => row.photoUrl, {
            id: 'url',
            cell: info => <img src={info.getValue() == '' ?  info.getValue() : NoImage} width={"38px"} />,
            header: () => <span>{t('productsTableHeader.url')}</span>,
        }),
        columnHelper.accessor(row => row.name, {
            id: 'name',
            cell: info => <NavLink to={'/product/' + info.row.original.id} className={"name"}>{info.getValue()}</NavLink>,
            header: () => <span>{t('productsTableHeader.name')}</span>,
        }),
        columnHelper.accessor(row => row.lastPurchaseDate, {
            id: 'lastPurchase',
            cell: info => <span>{info.getValue()}</span>,
            header: () => <span>{t('productsTableHeader.lastPurchase')}</span>,
        }),
        columnHelper.accessor(row => row.recommendedRetailPrice, {
            id: 'rrc',
            cell: info => <span>{Math.round(info.getValue() * wsRate)} {t('uah')}</span>,
            header: () => <span>{t('productsTableHeader.rrc')}</span>,
        }),
        columnHelper.accessor(row => row.price, {
            id: 'price',
            cell: info => <span>{Number(info.getValue()).toFixed(2)} {t('usd')}</span>,
            header: () => <span>{t('productsTableHeader.price')}</span>,
        }),
        columnHelper.accessor(row => row.available, {
            id: 'left',
            cell: info => <span><ProductAvailable count={info.getValue()} dateAvailable={info.row.original.dateAvailable}></ProductAvailable></span>,
            header: () => <span>{t('productsTableHeader.left')}</span>,
        }),
        columnHelper.accessor(row => row.toCart, {
            id: 'toCart',
            cell: info => <ProductCountTable buttonName={t('add')} appointment={"cart"} product={info.row.original}></ProductCountTable>,
            header: () => <span>{t('productsTableHeader.toCart')}</span>,
        }),
        columnHelper.accessor(row => row.totalPrice, {
            id: 'totalPrice',
            cell: info => <ProductCountSum price={info.row.original.price} id={info.row.original.id}></ProductCountSum>,
            header: () => <span>{t('productsTableHeader.totalPrice')}</span>,
        }),
        columnHelper.accessor(row => row.income, {
            id: 'income',
            cell: info => <span>{Number((info.row.original.recommendedRetailPrice - info.row.original.price) * info.row.original.count).toFixed(2)} {t('usd')}</span>,
            header: () => <span>{t('productsTableHeader.income')}</span>,
        }),
        columnHelper.accessor(row => row.compare, {
            id: 'compareHeader',
            cell: info => <GenericButton generic={"delete"} onClick={() => deleteItem(info.row.original.id)} isDisabled={false} showText={false} />,
            header: () => <span></span>,
        }),
    ]

    const [columns] = useState<typeof columnsCell>(() => [
        ...columnsCell,
    ])

    const [columnVisibility, setColumnVisibility] = useState({})


    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        state: {
            columnVisibility,
        },
        onColumnVisibilityChange: setColumnVisibility,
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
    })

    return (
        <div className={"table-wrapper"}>

            {isMobileView ? <div className={"table-mobile-view"}>
                {data.map((product, index) =>
                    <div className={"product-item"} key={index}>
                        <FlexboxGrid className={"product-info"}>
                            <FlexboxGrid.Item className={"product-info-vendorCode"}>
                                <div className="value vendorCode">{product.vendorCode}</div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="product-info-image">
                                <div className="value">
                                    <img src={product.photoUrl} alt=""/>
                                </div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className="product-info-name">
                                <div className="value">
                                    <NavLink to={'/product/' + product.id}>{product.name}</NavLink>
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                        <Row className={"product-info"}>
                            <Col xs={6}>
                                <div className="title">{t('productsTableHeader.lastPurchase')}</div>
                                <div className="value">{product.lastPurchaseDate}</div>
                            </Col>
                            <Col xs={6}>
                                <div className="title">{t('productsTableHeader.rrc')}</div>
                                <div className="value">{product.recommendedRetailPrice} {t('usd')}</div>
                            </Col>
                            <Col xs={6}>
                                <div className="title">{t('productsTableHeader.price')}</div>
                                <div className="value bold">{product.price} {t('usd')}</div>
                            </Col>
                            <Col xs={6}>
                                <div className="title">{t('productsTableHeader.left')}</div>
                                <div className="value">{product.available}</div>
                            </Col>
                        </Row>
                        <FlexboxGrid  className={"product-actions"}>
                            <FlexboxGrid.Item >
                                <div className="title">{t('productsTableHeader.toCart')}</div>
                                <div className="value">
                                    <ProductCountTable buttonName={t('add')} appointment={"cart"} product={product}></ProductCountTable>
                                </div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item >
                                <div className="title">{t('productsTableHeader.totalPrice')}</div>
                                <div className="value">
                                    {Number(product.price * product.count).toFixed(2)} {t('usd')}
                                </div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item>
                                <div className="title">{t('productsTableHeader.income')}</div>
                                <div className="value">{Number((product.recommendedRetailPrice - product.price) * product.count).toFixed(2)} {t('usd')}</div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item className={"compare-wrapper"}>
                                <div className="value">
                                    <GenericButton generic={"delete"} onClick={() => deleteItem(product.id)} isDisabled={false} showText={false} />
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </div>
                )}

            </div> : <div className={"table-responsive"}>
                <table>
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th className={header.id} key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id} className={cell.column.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>}

        </div>
    )
}
export default TableOrderEdit