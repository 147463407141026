import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import Header from "../../blocks/Header/Header";
import {NavLink, useParams} from "react-router-dom";
import KeyValuePaire from "../../components/KeyValuePaire/KeyValuePaire";
import TableCreatedReturn from "../../components/TableCreatedReturn/TableCreatedReturn";
import Footer from "../../blocks/Footer";

import GetReclamationModel from "../../dto/reclamations/GetReclamationModel";
import reclamationServices from "../../services/reclamationServices";
import DeliverySavedAddress
    from "../../components/OrderProducts/OrderProductsDelivery/delivery/DeliverySavedAddress/DeliverySavedAddress";

const CreatedReclamationPage:React.FC = () => {
    const {t} = useTranslation();

    let {reclamationId} = useParams();

    const id = reclamationId == undefined ? 0 : +reclamationId

    const [reclamationData, setReclamationData] = useState<GetReclamationModel>()

    useEffect(() => {
        reclamationServices.getReclamation(id).then((data) => {
            setReclamationData(data)
        })
    }, [])
    return (
        <div className="return-page-wrapper my-orders-page-wrapper created-return-page page-wrapper">
            <Header></Header>
            <main>
                <div className="page-title">
                    <div className="back-link">
                        <NavLink to='/return?activetab=0'>{t('createdReclamation.reclamationsList')}</NavLink>
                    </div>
                    <h2>№{reclamationData?.id} от {reclamationData?.dateCreated}</h2>

                    <div className={"white-box return-status status-" + reclamationData?.status}>

                        <div className="created-point">
                            <span></span>
                            {t('createdReturn.created')}
                        </div>
                        <div className="looking-point">
                            <span></span>
                            {t('createdReturn.underConsideration')}
                        </div>
                        <div className="closed-point">
                            <span></span>
                            {t('createdReturn.closed')}
                        </div>

                    </div>

                    <div className="white-box">
                        {reclamationData ?

                            reclamationData.diagnosticsResult || reclamationData.reclamationAction || reclamationData.reclamationCause || reclamationData.reclamationType ?
                                <div className="decision">
                                    <h3>{t('createdReclamation.result')}</h3>
                                    <div className="decision-info">
                                        {reclamationData.diagnosticsResult ? <KeyValuePaire label={t('createdReclamation.reclamationResult')} value={reclamationData.diagnosticsResult} /> : null}
                                        {reclamationData.reclamationAction ? <KeyValuePaire label={t('createdReclamation.reclamationAction')} value={reclamationData.reclamationAction} /> : null}
                                        {reclamationData.reclamationCause ? <KeyValuePaire label={t('createdReclamation.reclamationCause')} value={reclamationData.reclamationCause} /> : null}
                                        {reclamationData.reclamationType ? <KeyValuePaire label={t('createdReclamation.reclamationType')} value={reclamationData.reclamationType} /> : null}
                                    </div>
                                </div>

                                : null

                            : null}

                        {reclamationData ?

                                <div className="delivery">
                                    <h3>{t('createdReclamation.details')}</h3>
                                    <div className="delivery-info">
                                        <div className="delivery-contact-info">
                                            {reclamationData.contactName ? <KeyValuePaire label={t('createdReclamation.contactData')} value={reclamationData.contactName} /> : null}
                                            {reclamationData.contactPhone ? <KeyValuePaire label={t('createdReclamation.phone')} value={reclamationData.contactPhone} /> : null}
                                        </div>
                                        <div className="delivery-info-address">
                                            {reclamationData.deliveryAddress ? <div>
                                                    <div className="label">{t('createdReclamation.delivery')}</div>
                                                    <DeliverySavedAddress savedAddress={reclamationData.deliveryAddress} labels={false} />
                                                </div> : null}

                                            {reclamationData.shippingInvoiceNumber ? <KeyValuePaire label={t('createdReclamation.shippingInvoiceNumber')} value={reclamationData.shippingInvoiceNumber} /> : null}
                                        </div>
                                    </div>
                                </div>

                            : null}
                        {reclamationData?.comment ? <div>
                            <div className="title">{t('createdReclamation.comment')}</div>
                            <p>{reclamationData?.comment}</p>
                        </div> : null }

                    </div>

                    <h2>{t('createdReclamation.productsList')}</h2>

                    {reclamationData ? <div className="main-table"><TableCreatedReturn data={reclamationData.items} /></div> : null }


                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default CreatedReclamationPage