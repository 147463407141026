import React from "react";

import "./DeliverySavedAddress.sass"
import ShippingAddress from "../../../../../interfaces/ShippingAddress";
import {CarrierType} from "../../../../../enums/CarrierType";
import {useTranslation} from "react-i18next";
import {DeliveryType} from "../../../../../enums/DeliveryType";
import PaymentAndPickupData from "../../../../../interfaces/PaymentAndPickupData";

const DeliverySavedAddress: React.FC<{savedAddress: ShippingAddress | null, paymentPickupData?: PaymentAndPickupData | null, labels: boolean}> = ({savedAddress, paymentPickupData, labels}) => {
    const { t } = useTranslation();

    return (
        <div className="address">
            <div className="delivery-type">
                {labels ? <div className="label">Доставка</div> : null}
                {savedAddress?.carrierType == CarrierType.NewPost  ? <span>{t('carrierType.NewPost')}</span> : null}
                {savedAddress?.carrierType == CarrierType.MeestExpress  ? <span>{t('carrierType.MeestExpress')}</span> : null}
                {savedAddress?.carrierType == CarrierType.Delivery  ? <span>{t('carrierType.Delivery')}</span> : null}
                {savedAddress?.carrierType == CarrierType.AddressDelivery  ? <span>{t('carrierType.UkrPost')}</span> : null}
                {savedAddress?.carrierType == CarrierType.DPD  ? <span>{t('carrierType.Dpd')}</span> : null}
                {savedAddress?.carrierType == CarrierType.Raben  ? <span>{t('carrierType.Raben')}</span> : null}
                {paymentPickupData?.carModel ? <span>{t('carrierType.PickUp')}</span> : null},&nbsp;

                {savedAddress?.deliveryType == DeliveryType.CourierDelivery ? t('delivery.addressDelivery') : null}
                {savedAddress?.deliveryType == DeliveryType.BranchDelivery ? t('delivery.stockDelivery') : null}
            </div>
            {savedAddress ? <div className="delivery-address">
                {labels ? <div className="label">Адрес доставки</div> : null}
                {savedAddress.country ? <span>{savedAddress.country}</span> : null }
                {savedAddress.state ? <span>, {savedAddress.state}</span> : null }
                {savedAddress.district ? <span>, {savedAddress.district}</span> : null }
                {savedAddress.city ? <span>, {savedAddress.city}</span> : null }
                {savedAddress.carrierBranch ? <span>, {savedAddress.carrierBranch}</span> : null }
                {savedAddress.street ? <span>, {savedAddress.street}</span> : null }
                {savedAddress.house ? <span>, {savedAddress.house}</span> : null }
                {savedAddress.apartment ? <span>, {savedAddress.apartment}</span> : null }
            </div>: null}
            {paymentPickupData ? <div className="delivery-address">
                {paymentPickupData.carModel ? <span>{paymentPickupData.carModel}</span> : null }
                {paymentPickupData.carNumber ? <span>,&nbsp;{paymentPickupData.carNumber}</span> : null }
                {paymentPickupData.pickupDate ? <span>,&nbsp;{paymentPickupData.pickupDate}</span> : null }
                {paymentPickupData.pickupTime ? <span>,&nbsp;{paymentPickupData.pickupTime}</span> : null }
            </div>: null}

        </div>
    )
}
export default DeliverySavedAddress