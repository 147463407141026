import React, {useRef, useState} from "react";
import {Form, Schema} from "rsuite";
import {useTranslation} from "react-i18next";

import './LoginWithEmail.sass'

function TextField(props: any) {
    const { fieldName, value, setValue, accepter, label, ...rest } = props;
    return (
        <Form.Group controlId={`${fieldName}-3`} key={`${fieldName}-3`}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control
                name={fieldName}
                value={value}
                onChange={setValue}
                key={`${fieldName}-4`}
                accepter={accepter} {...rest}
            />
        </Form.Group>
    );
}

const LoginWithEmail:React.FC<{submitForm: Function, emailNotFound: boolean, loginError: string | null}> = ({submitForm, emailNotFound, loginError}) => {

    const { t } = useTranslation();

    const formRef = useRef<any>();
    const [formError, setFormError] = useState({});
    const [isFormValid, setFormValid] = useState(false)

    const [contactDataEmail, setContactDataEmail] = useState('')

    const errorField = t('form.requiredFieldMsg')
    const errorEmail = t('form.validEmailAddress')

    const email = t('form.email')

    const { StringType } = Schema.Types;
    const model = Schema.Model({
        email: StringType()
            .isEmail(errorEmail)
            .isRequired(errorField),
    });

    const checkErrors = () => {
        if (Object.keys(formError).length === 0){
            setFormValid(true)
        }

        if (formRef.current!.check()) {
            submitForm(contactDataEmail)
        }
    }

    return (

        <Form className={"login-email-wrapper"} model={model} ref={formRef} onCheck={setFormError}>
            <p className="mb-15">{t('login.subheader')}</p>
            <div className={isFormValid ? "input-wrapper has-error" : "input-wrapper"}>
                <TextField
                    name="email"
                    placeholder={email}
                    key={'email'}
                    value={contactDataEmail}
                    setValue={setContactDataEmail}
                />
            </div>
            {emailNotFound ? <div className="error-msg">{loginError}</div> : null}
            <button
                disabled={Object.keys(formError).length ? true: false}
                type="submit"
                className="btn btn-lg"
                onClick={checkErrors}
            >{t('login.submit')}
            </button>
        </Form>




    )
}
export default LoginWithEmail