import React from "react";
import {NavLink} from "react-router-dom";
import {Col, Row} from "rsuite";
import ProductCountTable from "../ProductCountTable/ProductCountTable";
import AddToComare from "../AddToComare/AddToComare";
import {useTranslation} from "react-i18next";
import {ProductEntity} from "../../interfaces/Product/ProductEntity";

import "./TileProduct.sass"


import NoImage from '../../assets/img/no_img_b.jpg';
import {useAppSelector} from "../../hooks";
import ProductAvailable from "../ProductAvailable/ProductAvailable";


const TileProduct: React.FC<
    {product: ProductEntity,
        showAdditionalControls: boolean,
        deleteFromCompareList: Function | null
    }> = (
        {product,
            showAdditionalControls,
            deleteFromCompareList}) => {
    const { t } = useTranslation();
    const wsRate = useAppSelector<number>((state) => state.wsRate.value);
    const location = process.env.REACT_APP_LOCATION ?? 'ua';

    return(
        <div className={!showAdditionalControls ? "product-wrapper-tile" :"product-wrapper-tile compare"}>
            <div className="img">
                {showAdditionalControls && deleteFromCompareList != null ? <button className={"removeFromCompare"} onClick={() => deleteFromCompareList(product.id)}></button> : null }
                <NavLink to={'/product/' + product.id} className={"name"}>
                    <img src={product.photoUrl == null ? NoImage : product.photoUrl} alt=""/>
                </NavLink>
            </div>
            <div className="h3">
                <NavLink to={'/product/' + product.id}>{product.name}</NavLink>
            </div>
            <Row className={"product-info"}>
                <Col xs={8} className={"product-info-rrc"}>
                    <div className="product-info_title">
                        {t('productsTableHeader.rrc')}
                    </div>
                    <div className="value">{location == 'ua'?Math.round(product.recommendedRetailPrice * wsRate): product.recommendedRetailPrice} {t('uah')}</div>
                </Col>
                <Col xs={8} className="center product-info-price">
                    <div className="product-info_title">{t('productsTableHeader.price')}</div>
                    <div className="value">{Number(product.price).toFixed(2)} {t('usd')}</div>
                </Col>
                {!showAdditionalControls ? <Col xs={8}  className="product-info-left">
                        {(product.available == 0 && product.dateAvailable) ? null : <div className="product-info_title">{t('productsTableHeader.left')}</div>}
                    <div className={(product.available == 0 && product.dateAvailable) ? "value date" : "value"}><ProductAvailable count={product.available} dateAvailable={product.dateAvailable}></ProductAvailable></div>
                </Col> : null}

            </Row>
            <div className="product-action">
                <div className={"product-action-add_cart"}>
                    <ProductCountTable buttonName={t('add_to_cart')} appointment={"cart"} product={product}></ProductCountTable>
                </div>
                {/*<div className={"product-action-add_reserve"}>
                    <ProductCountTable buttonName={t('add_to_reserve')} appointment={"reserve"} product={product}></ProductCountTable>
                </div>*/}
                {!showAdditionalControls ? <div className={"product-action-compare"}>
                    <AddToComare chosenProduct={product}></AddToComare>
                </div> : null}

            </div>
        </div>
    )
}
export default TileProduct