import i18next from "i18next";
import { createBrowserRouter } from 'react-router-dom';
import store from "../store";
import {setStateFetch} from "../slices/fetchStateSlice";
import ApiResponseModel from "../dto/ApiResponseModel";
import {Exception} from "sass";
import AuthProvider from "./AuthProvider";

export default abstract class BaseHttpHandler {
    private _baseUrl: string;
    private _withAuth: boolean;
    private _defaultContentType: string;
    private _token: string | null;
    private _withLocation: boolean;

    constructor(baseUrl: string, withAuth: boolean = true, withLocation: boolean = false, defaultContentType = "application/json", token: string | null = null) {
        this._baseUrl = baseUrl;
        this._withAuth = withAuth;
        this._defaultContentType = defaultContentType;
        this._token = token;
        this._withLocation = withLocation;
    }

    public get(url: string) {
        return this.fetchData(this._baseUrl + url, 'GET');
    };

    public getFile(url: string, method: string, data: object | string | null = null): Promise<Blob> {
        return this.fetchBlob(this._baseUrl + url, method, JSON.stringify(data));
    };

    public put(url: string, data: object | string | null = null) {
        return this.fetchData(this._baseUrl + url, 'PUT', JSON.stringify(data));
    };

    public post(url: string, data: object | null = null) {
        return this.fetchData(this._baseUrl + url, 'POST', JSON.stringify(data));
    };

    public delete(url: string) {
        return this.fetchData(this._baseUrl + url, 'DELETE');
    };

    public patch(url: string, data: object) {
        return this.fetchData(this._baseUrl + url, 'PATCH', JSON.stringify(data));
    };

    public refreshToken(url: string) {
        return fetch(this._baseUrl + url, {
            method: 'POST',
            cache: 'no-cache',
            redirect: 'follow',
            credentials: 'include',
        });
    };

    protected doRequest(url: string, method: string, body?: string, accept: string = 'application/json'): Promise<any> {
        let contentType = this._defaultContentType;

        if (body) {
            contentType = 'application/json';
        }
        let headers: {[key: string]: string} = {
            'Content-Type': contentType,
            //'Authorization': 'Bearer ' + localStorage.getItem('token'),//authManager.getToken(),
            'Accept-Language': i18next.language,
        };
        let credentialsState: RequestCredentials = 'omit';
        if(this._withLocation) {
            headers['Client-Location'] = process.env.REACT_APP_LOCATION ?? 'ua';
        }
        if(this._withAuth) {
            if(this._token){
                headers['Authorization'] = `Bearer ${this._token}`;
                credentialsState = 'include';
            }
            else {
                const token = AuthProvider.getAuthToken();
                if (token) {
                    headers['Authorization'] = `Bearer ${token}`;
                }
            }

        }
        const fetchState = store.getState().fetchState.value
        store.dispatch(setStateFetch(fetchState + 1))
        return fetch(url, {
            method: method,
            //cache: 'no-cache',
            credentials: credentialsState,
            headers: headers,
            //redirect: 'follow',
            mode: "cors",
            body: body
        })
            .then(async (response) => {
                if (response.ok) {
                    //const json = (await response.json()) as ApiResponseModel;
                    if(accept == 'application/json') {
                        return response.json();
                    }
                    if(accept == 'application/vnd.ms-excel' || accept == 'application/octet-stream') {
                        return response.blob();
                    }
                    return response.text();
                }
                if (response.status == 401) {
                    window.location.href = '/login';
                    AuthProvider.setAuthToken({token: null, expiration: null})
                   /* userService.refreshToken().then((data) => {
                        console.log(url);
                        authManager.saveToken(data.data.accessToken);
                    })
                        .catch((err) => {
                            //authManager.deleteToken();
                        });*/
                }
                const body = await response.json();
                throw new Error(body.message);
            })


            .finally(() => {
                const fetchState = store.getState().fetchState.value
                store.dispatch(setStateFetch(fetchState - 1))
            });
    }

    public fetchBlob(url: string, method: string, body?: string): Promise<Blob> {
        return this.doRequest(url, method, body, 'application/vnd.ms-excel');
    }
    public abstract fetchData(url: string, method: string, body?: string): Promise<any>;
}
