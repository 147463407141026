import React, {Fragment, HTMLProps, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import "./SettlementsTable.sass"
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    Row,
    RowSelectionState,
    useReactTable,
} from "@tanstack/react-table";
import {TransactionItems} from "../../dto/transactions/Transaction";
import TransactionsTableProducts from "./TransactionsTableProducts/TransactionsTableProducts";
import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";

type TableProps<TData> = {
    data: TData[]
    columns: ColumnDef<TData>[]
    renderSubComponent: (props: { row: Row<TData> }) => React.ReactElement
    getRowCanExpand: (row: Row<TData>) => boolean
}

function IndeterminateCheckbox({
                                   indeterminate,
                                   className = '',
                                   ...rest
                               }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!)

    useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }

    }, [ref, indeterminate])

    if(indeterminate){
        className = 'clear'
    }



    return (
        <div className="transaction-checkbox-wrapper">
            <input
                type="checkbox"
                ref={ref}
                className={className + ' cursor-pointer'}
                {...rest}
            />
            <span className={`checkbox ${className}`}></span>
        </div>
    )
}

const TransactipnsTable:React.FC<{data: TransactionItems[], onRowSelectionChange: Function}> = ({data, onRowSelectionChange}) => {
    const {t} = useTranslation();

    const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
    onRowSelectionChange(rowSelection)

    const [deviceWidth, setDeviceWidth] =  useState(window.innerWidth)

    const [isMobileView, setIsMobileView] = useState(true)

    useEffect(() => {
        if(deviceWidth <= 860) {
            setIsMobileView(true)
        }
        else {
            setIsMobileView(false)
        }

    }, [deviceWidth])

    useEffect(() => {
        const handleWindowResize = () => setDeviceWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [])

    const [deviceHeight, setDeviceHeight] =  useState(window.innerHeight)

    useEffect(() => {
        let height = deviceHeight - 450
        setDeviceHeight(height)
    }, [])

    const columns: ColumnDef<TransactionItems>[] = [
        {
            id: 'select-col',
            accessorFn: row => row.id,
            header: ({ table }) => (
                <IndeterminateCheckbox
                    checked={table.getIsAllRowsSelected()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    onChange={table.getToggleAllRowsSelectedHandler()}
                />
            ),
            cell: ({ row }) => (
                <IndeterminateCheckbox
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    onChange={row.getToggleSelectedHandler()}
                />
            ),
            footer: props => props.column.id,
        },
        {
            id: 'date',
            accessorFn: row => row.date,
            cell: ({ row, getValue }) => <span className="transaction-date">{getValue<string>()}</span>,
            header: () => <span className="date">{t('settlementCsv.date')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'documentName',
            accessorFn: row => row.documentName,
            cell: ({ row, getValue }) => <span className="transaction-documentName">{getValue<string>()}</span>,
            header: () => <span className="documentName">{t('settlementCsv.document')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'initialBalance',
            accessorFn: row => row.initialBalance,
            cell: ({ row, getValue }) => <span className="transaction-sinitialBalance">{getValue<string>()} {t('usd')}</span>,
            header: () => <span className="initialBalance">{t('settlementCsv.startBalance')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'debit',
            accessorFn: row => row.debit,
            cell: ({ row, getValue }) => <span className="transaction-credit">{getValue<string>()} {t('usd')}</span>,
            header: () => <span className="credit">{t('settlementCsv.orderAmount')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'credit',
            accessorFn: row => row.credit,
            cell: ({ row, getValue }) => <span className="transaction-debit">{getValue<string>()} {t('usd')}</span>,
            header: () => <span className="credit">{t('settlementCsv.paymentAmount')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'finalBalance',
            accessorFn: row => row.finalBalance,
            cell: ({ row, getValue }) => <span className="transaction-finalBalance">{getValue<string>()} {t('usd')}</span>,
            header: () => <span className="finalBalance">{t('settlementCsv.finalBalance')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'products',
            accessorFn: row => row.orderItems,
            cell: ({ row }) => {
                return row.original.orderItems?.length ? (
                    <button
                        {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: { cursor: 'pointer' },
                        }}
                    >
                        <span className="show-hide">{row.getIsExpanded() ? <CollaspedOutlineIcon /> : <ExpandOutlineIcon />}</span>
                    </button>
                ) : (
                    ''
                )
            },
            header: () => <span>#</span>,
            footer: props => props.column.id,
        },
    ]

    const columnsMobile: ColumnDef<TransactionItems>[] = [
        {
            id: 'select-col',
            accessorFn: row => row.id,
            header: ({ table }) => (
                <IndeterminateCheckbox
                    checked={table.getIsAllRowsSelected()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    onChange={table.getToggleAllRowsSelectedHandler()}
                />
            ),
            cell: ({ row }) => (
                <IndeterminateCheckbox
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    onChange={row.getToggleSelectedHandler()}
                />
            ),
            footer: props => props.column.id,
        },
        {
            id: 'date',
            accessorFn: row => row.date,
            cell: ({ row, getValue }) => <span className="transaction-date">{getValue<string>()}</span>,
            header: () => <span className="date">{t('settlementCsv.date')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'documentName',
            accessorFn: row => row.documentName,
            cell: ({ row, getValue }) => <span className="transaction-documentName">{getValue<string>()} {t('usd')}</span>,
            header: () => <span className="documentName">{t('settlementCsv.document')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'initialBalance',
            accessorFn: row => row.initialBalance,
            cell: ({ row, getValue }) => <span className="transaction-sinitialBalance">{getValue<string>()}</span>,
            header: () => <span className="initialBalance">{t('settlementCsv.startBalance')}</span>,
            footer: props => props.column.id,
        },
        {
            id: 'products',
            accessorFn: row => row.orderItems,
            cell: ({ row }) => {
                return row.original.orderItems?.length !== 0 ? (
                    <button
                        {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: { cursor: 'pointer' },
                        }}
                    >
                        <span className="show-hide">{row.getIsExpanded() ? <CollaspedOutlineIcon /> : <ExpandOutlineIcon />}</span>
                    </button>
                ) : (
                    ''
                )
            },
            header: () => <span>#</span>,
            footer: props => props.column.id,
        },
    ]

    function Table({
                       data,
                       columns,
                       renderSubComponent,
                       getRowCanExpand,
                   }: TableProps<TransactionItems>): JSX.Element {
        const table = useReactTable<TransactionItems>({
            data,
            columns: isMobileView ? columnsMobile : columns,
            getRowCanExpand,
            getCoreRowModel: getCoreRowModel(),
            getExpandedRowModel: getExpandedRowModel(),
            onRowSelectionChange: setRowSelection,
            state: {
                rowSelection, //pass the row selection state back to the table instance
            },
            getRowId: row => row.id.toString(),
        })

        return (
            <div>
                    <table className={isMobileView ? 'mobile' : ''}>
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <th key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                            <div>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                            </div>
                                        )}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                            <Fragment key={row.id}>
                                <tr>
                                    {/* first row is a normal row */}
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                                {row.getIsExpanded() && (
                                    <tr>
                                        {/* 2nd row is a custom 1 cell row */}
                                        <td colSpan={row.getVisibleCells().length}>
                                            {renderSubComponent({ row })}
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        )
                    })}
                    </tbody>
                </table>

            </div>
        )
    }

    const renderSubComponent = ({ row }: { row: Row<TransactionItems> }) => {
        return (
            <TransactionsTableProducts data={row.original.orderItems} />
        )
    }

    return (
        <div>
            <Table
                data={data}
                columns={columns}
                getRowCanExpand={() => true}
                renderSubComponent={renderSubComponent}
            />
        </div>
    )
}
export default TransactipnsTable