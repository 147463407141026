import SelectedFiltersModel from "../dto/products/SelectedFiltersModel";
import PaginatedResult from "../dto/PaginatedResult";
import ShortProductModel from "../dto/products/ShortProductModel";
import ApiResponseModel from "../dto/ApiResponseModel";
import ProductsPageModel from "../dto/pages/ProductsPageModel";
import {Category, CategoryWrapper} from "../interfaces/Category";
import {AutocompleteInterface} from "../interfaces/Autocomplete";
import SingleProductModel from "../dto/products/SingleProductModel";
import ApiHttpHandler from "./apiHttpHandler";

const handler = new ApiHttpHandler();

const getCategories = (location?: string): Promise<CategoryWrapper> => {
    return handler.get(`products/categories?location=${location}`)
};

const getProducts = (filters: SelectedFiltersModel): Promise<ProductsPageModel> =>  {
    return handler.post(`products`, filters)
};

const getProduct = (id: number): Promise<SingleProductModel> =>  {
    return handler.get(`products/${id}`)
};

const getAutocomplete = (query: string): Promise<AutocompleteInterface> => {
    return handler.get(`products/autocomplete?query=${query}`)
}

const getProductsSearch = (query: string, page: number, itemsPerPage: number): Promise<PaginatedResult<ShortProductModel>> => {
        return handler.get(`products/search/?query=${query}&page=${page}&itemsPerPage=${itemsPerPage}`)
}

export default {
    getCategories,
    getProducts,
    getProduct,
    getAutocomplete,
    getProductsSearch
};