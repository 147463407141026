import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Routes, Route, useNavigate} from "react-router-dom";

import PrivateRoutes from "./services/PrivateRoutes";

import Home from "./pages/Home";

import './App.sass';
import Login from "./pages/Login/Login";
import ProductPage from "./pages/ProductPage/ProductPage";
import Comparison from "./pages/ComparisonPage/ComparisonPage";
import OrderPage from "./pages/OrderPage/OrderPage";

import {useAppSelector} from "./hooks";
import {CustomProvider, Loader} from "rsuite";
import CartPage from "./pages/CartPage/CartPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import AppInit from "./components/AppInit/AppInit";
import Auth from "./components/Auth/Auth";
import MyOrdersPage from "./pages/MyOrders/MyOrdersPage";
import OrderSinglePage from "./pages/OrderSinglePage/OrderSinglePage";
import MyManagerPage from "./pages/MyManagerPage/MyManagerPage";
import SettlementsPage from "./pages/SettlementsPage/SettlementsPage";
import MyReserve from "./pages/MyReserve/MyReserve";
import ReturnPage from "./pages/ReturnPage/ReturnPage";
import CreateReturnPage from "./pages/ReturnPage/CreateReturnPage/CreateReturnPage";
import CreatedReturnPage from "./pages/ReturnPage/CreatedReturnPage/CreatedReturnPage";
import CreateReclamationPage from "./pages/ReturnPage/CreateReclamationPage/CreateReclamationPage";
import CreatedReclamationPage from "./pages/ReturnPage/CreatedReclamationPage";
import BalancePage from "./pages/BalancePage/BalancePage";
import SubscriptionPage from "./pages/SubscriptionPage/SubscriptionPage";
import HelpPage from "./pages/HelpPage/HelpPage";
import SearchPage from "./pages/SearchPage/SearchPage";
import Page404 from "./pages/Page404/Page404";
import {PermissionsConstants} from "./const/Permissions";
import {ruRU} from "rsuite/locales";
import pl from "date-fns/locale/pl"
import uk from "date-fns/locale/uk";
import OrderTrackPage from "./pages/OrderSinglePage/OrderTrackPage/OrderTrackPage";
import EditPage from "./pages/Admin/EditPage/EditPage";
import AllOrders from "./pages/Admin/AllOrders/AllOrders";
import AllReturns from "./pages/Admin/AllReturns/AllReturns";
import AllReserve from "./pages/Admin/AllReserve/AllReserve";
import ManagerBankDetails from "./pages/Admin/ManagerBankDetails/ManagerBankDetails";
import UpdateData from "./pages/Admin/UpdateData/UpdateData";
import calendarByLanguage from "./pages/Login/utilits/calendarByLanguage";
import otherTranslateByLanguage from "./pages/Login/utilits/otherTranslateByLanguage";

declare type PickKeys<T> = {
    [keys in keyof T]?: T[keys];
};

function App() {
    const stateFetch = useAppSelector((state) => state.fetchState.value);

    let currentLang: string = useAppSelector((state) => state.currentLang.value);

    const [localeLang, setLocaleLang] = useState<PickKeys<typeof plPL>>()

    useEffect(() => {

        if(currentLang == 'uk'){
            setLocaleLang(ukUA)
        }
        if(currentLang == 'pl'){
            setLocaleLang(plPL)
        }
        else {
            setLocaleLang(plPL)
        }

    }, [currentLang])


    const Calendar = calendarByLanguage(currentLang);

    const plPL: PickKeys<typeof ruRU> = otherTranslateByLanguage(currentLang, Calendar)
    const ukUA: PickKeys<typeof ruRU> = otherTranslateByLanguage(currentLang, Calendar)

  return (
      <CustomProvider locale={localeLang}>
    <div className="App">
        <Router>
            <AppInit>
                <Routes>
                <Route path="/order/track/" element={<OrderTrackPage />}>
                    <Route index element={<OrderTrackPage />} />
                    <Route path=":orderId" element={<OrderTrackPage />} />
                </Route>
                    <Route path="*"  element={<Page404 />}>
                        <Route index element={<Page404 />} />
                    </Route>
                <Route element={<PrivateRoutes />}>
                    <Route path="/" element={<Home />}>
                        <Route index element={<Home />} />
                    </Route>
                    <Route path="/category/" element={<Home />}>
                        <Route index element={<Home />} />
                        <Route path=":categoryId" element={<Home />} />
                    </Route>
                    <Route path="/product/" element={<ProductPage />}>
                        <Route index element={<ProductPage />} />
                        <Route path=":productId" element={<ProductPage />} />
                    </Route>
                    {/*<Route element={<Auth allowedPermission={"se"} />}>*/}
                    <Route path="/cart" element={<CartPage />}>
                        <Route index element={<CartPage />} />
                    </Route>
                    {/*</Route>*/}
                    <Route path="/compare" element={<Comparison />}>
                        <Route index element={<Comparison />} />
                    </Route>
                    <Route path="/new-order/:orderId?" element={<OrderPage />}>
                        <Route index element={<OrderPage />} />
                    </Route>
                    <Route path="/copy-order/:orderId" element={<OrderPage isCopy={true} />}>
                        <Route index element={<OrderPage isCopy={true} />} />
                    </Route>
                    <Route path="/new-reserve/" element={<OrderPage isReserve={true} />}>
                        <Route index element={<OrderPage isReserve={true} />} />
                    </Route>
                    <Route path="/my-orders" element={<MyOrdersPage />}>
                        <Route index element={<MyOrdersPage />} />
                    </Route>
                    <Route path="/order/" element={<OrderSinglePage />}>
                        <Route index element={<OrderSinglePage />} />
                        <Route path=":orderId" element={<OrderSinglePage />} />
                    </Route>
                    <Route path="/my-managers/" element={<MyManagerPage />}>
                        <Route index element={<MyManagerPage />} />
                    </Route>
                    <Route path="/profile" element={<ProfilePage />}>
                        <Route index element={<ProfilePage />} />
                    </Route>
                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewTransactions} />}>
                        <Route path="/settlements" element={<SettlementsPage />}>
                            <Route index element={<SettlementsPage />} />
                        </Route>
                    </Route>
                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewReserves} />}>
                        <Route path="/my-reserve/" element={<MyReserve />}>
                            <Route index element={<MyReserve />} />
                        </Route>
                    </Route>
                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewReclamations} />}>
                        <Route path="/return" element={<ReturnPage />}>
                            <Route index element={<ReturnPage />} />
                        </Route>
                    </Route>
                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewReclamations} />}>
                        <Route path="/return/:returnId" element={<CreatedReturnPage />}>
                            <Route index element={<CreatedReturnPage />} />
                        </Route>
                    </Route>
                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewReclamations} />}>
                        <Route path="/create-return" element={<CreateReturnPage />}>
                            <Route index element={<CreateReturnPage />} />
                        </Route>
                    </Route>
                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewReclamations} />}>
                        <Route path="/create-reclamation" element={<CreateReclamationPage />}>
                            <Route index element={<CreateReclamationPage />} />
                        </Route>
                    </Route>
                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewReclamations} />}>
                        <Route path="/reclamation/:reclamationId" element={<CreatedReclamationPage />}>
                            <Route index element={<CreatedReclamationPage />} />
                        </Route>
                    </Route>
                    <Route path="/balance" element={<BalancePage />}>
                        <Route index element={<BalancePage />} />
                    </Route>
                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewSubscriptions} />}>
                        <Route path="/subscription" element={<SubscriptionPage />}>
                            <Route index element={<SubscriptionPage />} />
                        </Route>
                    </Route>
                    <Route path="/help" element={<HelpPage />}>
                        <Route index element={<HelpPage />} />
                    </Route>
                    <Route path="/search" element={<SearchPage />}>
                        <Route index element={<SearchPage />} />
                    </Route>



                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewAdmin} />}>
                        <Route path="/admin-edit" element={<EditPage />}>
                            <Route index element={<EditPage />} />
                        </Route>
                    </Route>
                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewAdmin} />}>
                        <Route path="/admin-orders" element={<AllOrders />}>
                            <Route index element={<AllOrders />} />
                        </Route>
                    </Route>
                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewAdmin} />}>
                        <Route path="/admin-returns" element={<AllReturns />}>
                            <Route index element={<AllReturns />} />
                        </Route>
                    </Route>
                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewAdmin} />}>
                        <Route path="/admin-reserve" element={<AllReserve />}>
                            <Route index element={<AllReserve />} />
                        </Route>
                    </Route>
                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewAdmin} />}>
                        <Route path="/admin-managers" element={<ManagerBankDetails />}>
                            <Route index element={<ManagerBankDetails />} />
                        </Route>
                    </Route>
                    <Route element={<Auth allowedPermission={PermissionsConstants.ViewAdmin} />}>
                        <Route path="/admin-updates" element={<UpdateData />}>
                            <Route index element={<UpdateData />} />
                        </Route>
                    </Route>

                </Route>
                <Route element={<Login/>} path="/login"/>
            </Routes>
            </AppInit>
        </Router>
            {stateFetch > 0 ? <div style={{ height: '100vh', width: '100vw', background: '#000', position: 'fixed', zIndex: 1060, top: 0, left: 0, opacity: .4 }}>
                <Loader inverse center content="" />
            </div> : null}
    </div>
      </CustomProvider>
  );
}

export default App;
