type CalendarType = {
    sunday: string;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    ok: string;
    today: string;
    yesterday: string;
    hours: string;
    minutes: string;
    seconds: string;
    formattedMonthPattern: string;
    formattedDayPattern: string;
    dateLocale: any;
};

function otherTranslateByLanguage (lang: string, Calendar: CalendarType) {
    if (lang === 'pl'){
        return {
            common: {
                loading: 'Ładowanie...',
                emptyMessage: 'Brak danych'
            },
            Calendar,
            DatePicker: {
                ...Calendar
            },
            DateRangePicker: {
                ...Calendar,
                last7Days: 'Ostatnie 7 dni'
            },
            Picker: {
                noResultsText: 'Nic nie znaleziono',
                placeholder: 'Wybierz',
                searchPlaceholder: 'Szukaj',
                checkAll: 'Wszystko'
            },
            InputPicker: {
                newItem: 'Nowy towar',
                createOption: 'Utwórz opcję "{0}"'
            },
            Uploader: {
                inited: 'Zainicjowany',
                progress: 'Ładowanie',
                error: 'Błąd',
                complete: 'Gotowe',
                emptyFile: 'Pusty',
                upload: 'Załaduj'
            },
            CloseButton: {
                closeLabel: 'Zamknij'
            },
            Breadcrumb: {
                expandText: 'Pokaż ścieżkę'
            },
            Toggle: {
                on: 'Otwarty',
                off: 'Zamknięty'
            }
        }
    }
    else {
        return {
            common: {
                loading: 'Завантаження...',
                emptyMessage: 'Даних не знайдено'
            },
            Calendar,
            DatePicker: {
                ...Calendar
            },
            DateRangePicker: {
                ...Calendar,
                last7Days: 'Останні 7 днів'
            },
            Picker: {
                noResultsText: 'Нічого не знайдено',
                placeholder: 'Виберіть',
                searchPlaceholder: 'Пошук',
                checkAll: 'Все'
            },
            InputPicker: {
                newItem: 'Новий товар',
                createOption: 'Створити параметр "{0}"'
            },
            Uploader: {
                inited: 'Початковий',
                progress: 'Завантаження',
                error: 'Помилка',
                complete: 'Готово',
                emptyFile: 'Порожній',
                upload: 'Завантажити'
            },
            CloseButton: {
                closeLabel: 'Закрити'
            },
            Breadcrumb: {
                expandText: 'Показати шлях'
            },
            Toggle: {
                on: 'Відчинено',
                off: 'Закрити'
            }
        }
    }
}

export default otherTranslateByLanguage;