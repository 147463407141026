import React from "react";
import {useTranslation} from "react-i18next";

import ProductCount from "../ProductCount/ProductCount";
import {CartOrder} from "../../interfaces/CartProduct";

import "./CartOrderProducts.sass"
import GetOrderModel from "../../dto/order/GetOrderModel";

const CartOrderProducts: React.FC<{
    order: CartOrder,
    countButton: boolean,
    removeProduct?: Function,
    onProductCountChange?: (productId: number, count: number) => void,
    missingItems: {
        id: number,
        available: number
    }[] | null,}> =
    ({order, countButton, removeProduct, onProductCountChange, missingItems = null}) => {
        const { t } = useTranslation();


        return (
            <div className={"order-product"}>
                {order.orderItems.map((product, index) =>
                    <div className={missingItems?.find(item => item.id == product.product.id) ? "product missed" : 'product'} key={index}>
                        <div className={"wrapper-product"}>
                            <div className={"product-name"}>{product.product.name}</div>
                            <div className={"code"}>{product.product.vendorCode}</div>
                            <div className={"count"}>{countButton ?
                                <ProductCount product={product.product} count={product.count} onCountChange={onProductCountChange}/> :
                                missingItems?.find(item => item.id == product.product.id) ?
                                    null
                                    : <span className="product-count">{product.count} {t('order.piece')} x</span>
                            }</div>
                            {missingItems?.find(item => item.id == product.product.id) ?
                                <div className={"missing-products"}>
                                    <ProductCount product={product.product} count={product.count} onCountChange={onProductCountChange}/>
                                </div>
                                : null}
                            <div className={"price"}>{Number(product.product.price * product.count).toFixed(2)} {t('usd')}</div>
                            {missingItems?.map(item => {
                                if (item.id == product.product.id){
                                    if (item.available == 0){
                                        return (
                                            removeProduct != null ? <button onClick={() => removeProduct(product.product.id)} className={"btn-usual delete-active_order"}>{t('order.delete')}</button> : null
                                        )
                                    }
                                }
                            }
                            ) }
                        </div>
                        {removeProduct != null && missingItems == null? <button onClick={() => removeProduct(product.product.id)} className={"remove"}></button> : null}

                    </div>
                )}
            </div>
        )
    }
export default CartOrderProducts;