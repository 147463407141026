import React, {SyntheticEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Input, InputGroup, InputNumber, Message, SelectPicker, Toggle} from "rsuite";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

import OrderProductsDelivery from "../OrderProductsDelivery/OrderProductsDelivery"

import deliveryAddressService from "../../../services/deliveryAddressService";
import ShippingAddress from "../../../interfaces/ShippingAddress";
import Pagination from "../../../interfaces/Pagination";

import SearchIcon from '@rsuite/icons/Search';

import GenericButton from "../../Buttons/GenericButton";
import DeliverySavedAddress from "../OrderProductsDelivery/delivery/DeliverySavedAddress/DeliverySavedAddress";


import {ItemDataType} from "rsuite/cjs/@types/common";
import PaymentAndPickupData from "../../../interfaces/PaymentAndPickupData";
import AddOrderModel from "../../../dto/order/AddOrderModel";
import {DeliveryType} from "../../../enums/DeliveryType";

import './OrderProductsShipping.sass'
import {CarrierType} from "../../../enums/CarrierType";
import {useAppSelector} from "../../../hooks";
import User from "../../../interfaces/User";
import {futimes} from "fs";


interface SelectItem{
    label: string,
    value: string
}

const Field = React.forwardRef((props: any, ref: any) => {
    const { name, message, label, accepter, error, defaultValue, ...rest } = props;
    return (
        <Form.Group controlId={`${name}-10`} ref={ref} className={error ? 'has-error' : ''}>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control name={name} accepter={accepter} defaultValue={defaultValue} errorMessage={error} {...rest} />
            <Form.HelpText>{message}</Form.HelpText>
        </Form.Group>
    );
});

const OrderProductsShipping: React.FC<{order: AddOrderModel,
    onSavedAddressChange: Function,
    onNewAddressChange: Function,
    onPaymentPickupDataChange: (data: PaymentAndPickupData) => void,
    errorsStateShipping: boolean,
    showPayOnDelivery: boolean,
    showDeliveryPayer: boolean,
    updateOrderNpClientName: ((state: boolean, cartOrderId: number) => void ) | null,
    updatePaymentControl: ((state: boolean, cartOrderId: number) => void ) | null}> = (
    {order,
        onSavedAddressChange,
        onNewAddressChange,
        onPaymentPickupDataChange,
        errorsStateShipping,
        showPayOnDelivery,
        showDeliveryPayer,
        updateOrderNpClientName,
        updatePaymentControl}) => {
    const {t} = useTranslation();
    const [tabIndex, setTabIndex] = useState(0);
    const addressShipping = t('order.addressShipping')
    const payer = t('delivery.payer')
    const sum = t('delivery.sum')

    const user = useAppSelector<User>(state => state.user.value)

    const [deliveryAddressList, setDeliveryAddressList] = useState<ShippingAddress[]>([])
    const [deliveryPayer] = useState([{label: t('deliveryPayer.sender'), value: 0}, {label: t('deliveryPayer.receiver'), value: 1}])
    const [chosenAddress, setChosenAddress] = useState<ShippingAddress | null>(null)
    const [toggleEditAddress, setToggleEditAddress] = useState(false)
    const [isPickup, setIsPickup] = useState<boolean>(order?.isPickup?? false)

    const [searchTerm, setSearchTerm] = useState('')
    const searchShippingAddress = (value: string, event: any) => {
        if (value.length >= 2){
            setSearchTerm(value)
        } else {
            setSearchTerm('')
        }

    }

    let addressPagination: Pagination = {
        "totalPageCount": 1,
        "totalItemCount": 50,
        "currentPageNumber": 1,
        "pageSize": 10,
    }

    useEffect(() => {
        if (order.deliveryAddress) {
            setChosenAddress(order.deliveryAddress);
            setToggleEditAddress(true);
        }


    }, [])

    useEffect(() => {
        deliveryAddressService.getDeliveryAddresses(searchTerm, addressPagination.currentPageNumber, addressPagination.pageSize)
            .then(data => {
                setDeliveryAddressList(data.items)
            })

    }, [searchTerm])

    const [openListAddresses, setOpenListAddresses] = useState(false)

    const openAddresses = () => {
        setOpenListAddresses(true)
        //setOpenChosenAddress(false)
    }

    const selectSavedAddress = (address: ShippingAddress) => {
        onSavedAddressChange(address.id)

        setChosenAddress(address)

        setOpenListAddresses(false)
        setToggleEditAddress(true)
        setPaymentPickupData({...paymentPickupData, pickupTime: null, pickupDate: null, carNumber: null, carModel: null})
        setIsPickup(false)
    }

    const isEditAddress = () => {
        setToggleEditAddress(current => !current)
    }

    const saveNewAddress = (data: ShippingAddress) => {
        setChosenAddress(data)
        setPaymentPickupData({...paymentPickupData, pickupTime: null, pickupDate: null, carNumber: null, carModel: null})
        onNewAddressChange(data)
        setToggleEditAddress(true)
        setIsPickup(false)
    }

    const [paymentPickupData, setPaymentPickupData] = useState<PaymentAndPickupData>({
        deliveryPayer: order.deliveryPayer,
        orderToPay: order.cashOnDeliveryAmount,
        carModel: order.carModel,
        carNumber: order.carNumber,
        pickupDate: order.pickupDate,
        pickupTime: order.pickupTime
    })

    const deliveryPayerChange = (value: string | null, item: ItemDataType, event: SyntheticEvent<Element, Event>) => {
        setPaymentPickupData({...paymentPickupData, deliveryPayer: +value!})
        if(onPaymentPickupDataChange) {
            onPaymentPickupDataChange({...paymentPickupData, deliveryPayer: +value!})
        }
    }

    const setOrderAmount = (value: string , event: any) => {
        value = event.target.value.replace(/[^0-9]/g, "")

        if(+value == 0) {
            setIsPaymentControl(false);
        }

        setPaymentPickupData({...paymentPickupData, orderToPay: +value!})
        if(onPaymentPickupDataChange) onPaymentPickupDataChange({...paymentPickupData, orderToPay: +value!})
    }

    const updatePaymentPickupData = (data: PaymentAndPickupData) => {
        setPaymentPickupData(data)
        if (data.pickupDate != null || data.pickupTime != null
        || data.carModel != null || data.carNumber != null) {
            setChosenAddress(null)
            onNewAddressChange(null)
            setToggleEditAddress(true)
            setIsPickup(true)
        }
        if(onPaymentPickupDataChange) {
            onPaymentPickupDataChange(data)
        }
    }

    const focusInput = (el: any) => {

        const inputs = document.getElementsByClassName('rs-picker-search-bar-input')
        for (let i = 0; i < inputs.length; i++) {
            const slide = inputs[i] as HTMLElement;
            slide.focus();
        }
    }



    useEffect(() => {

        if (updateOrderNpClientName && chosenAddress?.carrierType == CarrierType.NewPost) {
            updateOrderNpClientName(user.useNPNameByDefault,  order.cartOrderId)
        } else if (updateOrderNpClientName) {
            updateOrderNpClientName(false,  order.cartOrderId)
        }
    }, [chosenAddress?.carrierType])


    const [isChangeBuDefault, setIsChangeBuDefault] = useState(user.useNPNameByDefault)

    useEffect(() => {
        if (updateOrderNpClientName) {
            updateOrderNpClientName(user.useNPNameByDefault, order.tempId)
        }
    }, [])

    const changeUseNameByDefault = (checked: boolean, event: any) => {

        setIsChangeBuDefault(checked)

        if (updateOrderNpClientName) {
            updateOrderNpClientName(checked, order.tempId)
        }

        if (!checked) {
            setIsPaymentControl(false)

            /*if (updatePaymentControl) {
                updatePaymentControl(false, order.tempId)
            }*/
        }

    }



    const [isPaymentControl, setIsPaymentControl] = useState(order.usePaymentControl)

    const changePaymentControl = (checked: boolean, event: any) => {

        setIsPaymentControl(checked)

        if (updatePaymentControl) {
            updatePaymentControl(checked, order.tempId)
        }

    }


    return(
        <div className="shipping_wrapper-body">
            <div className="shipping-header-wrapper">
                <h3>{t('order.shipping')}</h3>

                {toggleEditAddress ? <GenericButton
                        generic={"edit"}
                        onClick={isEditAddress}
                        isDisabled={false}
                        showText={true}
                    />
                  : null}
            </div>
            {errorsStateShipping ? null : <Message className="error-sm" showIcon type="error">
                <p>{t('order.errorAddressShipping')}</p>
            </Message>}
            {!toggleEditAddress ?
                <Tabs className="delivery-tabs" selectedIndex={tabIndex} onSelect={setTabIndex}>
                <TabList>
                    <Tab>{t('delivery.savedAddress')}</Tab>
                    <Tab>{t('delivery.newDelivery')}</Tab>
                </TabList>

                <TabPanel>
                    <div className="search-shipping-wrapper">
                        <InputGroup inside>
                            <Input placeholder={addressShipping} onChange={searchShippingAddress} onFocus={openAddresses} />
                            <InputGroup.Button>
                                <SearchIcon  color="#0090D4" />
                            </InputGroup.Button>
                        </InputGroup>
                        {openListAddresses ? <div className="choose-recipient_list">
                            {deliveryAddressList.map((address, index) => {
                                return (
                                    <button key={index} className="choose-address-shipping" onClick={() => selectSavedAddress(address)}>

                                        <span>
                                            {address.deliveryType == DeliveryType.CourierDelivery ? t('delivery.stockDelivery') : null}
                                            {address.deliveryType == DeliveryType.BranchDelivery ? t('delivery.addressDelivery') : null},&nbsp;
                                        </span>
                                        <span>{address?.carrierType == CarrierType.NewPost ? t('carrierType.NewPost') : null}
                                            {address?.carrierType == CarrierType.MeestExpress ? t('carrierType.MeestExpress') : null}
                                            {address?.carrierType == CarrierType.Delivery ? t('carrierType.Delivery') : null}
                                            {address?.carrierType == CarrierType.AddressDelivery ? t('carrierType.UkrPost') : null}, &nbsp;</span>
                                        <span>{address.city}, &nbsp;</span>
                                        <span>{address.state}, &nbsp;</span>
                                        {address.carrierBranch ? <span>{address.carrierBranch}, &nbsp;</span> : null}
                                        {address.street ? <span>{address.street} &nbsp;</span> : null}
                                        {address.house ? <span>{address.house} &nbsp;</span> : null}
                                        {address.apartment ? <span>{address.apartment}</span> : null}

                                    </button>
                                )
                            })}
                        </div> : null}



                    </div>
                </TabPanel>
                <TabPanel>
                    <OrderProductsDelivery
                        onChange={saveNewAddress}
                        onPaymentPickupDataChange={updatePaymentPickupData}
                        showPayOnDelivery={showPayOnDelivery}
                        weight={order.weight}
                    />
                </TabPanel>
            </Tabs>
                : null}

            {toggleEditAddress ?
                <div>
                    <DeliverySavedAddress savedAddress={chosenAddress} paymentPickupData={paymentPickupData} labels={false} />
                    {(chosenAddress?.carrierType == CarrierType.NewPost && user.npClientName !== null) ?  <div className="dropship-wrapper">
                        <strong>{t('profile.npClientName')}:</strong>
                        <div className="mt-15">
                            <Toggle checked={isChangeBuDefault} onChange={changeUseNameByDefault} className="comparison-difference" /> {user.npClientName}
                        </div>
                    </div> : null}
                </div>: null}


            {(toggleEditAddress && !isPickup) || tabIndex == 0 ?
            <div className="payment-info-wrapper">
                <Form>
                    {showDeliveryPayer ? <div className="input-wrapper selectpicker input-payer">
                        <Field
                            name="payer"
                            label={payer}
                            accepter={SelectPicker}
                            value={paymentPickupData.deliveryPayer ?? 1}
                            searchable={false}
                            data={deliveryPayer}
                            onSelect={deliveryPayerChange}
                            defaultValue={1}
                            onOpen={focusInput}
                        />
                    </div> : null }

                    {showPayOnDelivery ? <div className="input-wrapper input-sum">
                        <Field
                            name="sum"
                            label={sum}
                            value={paymentPickupData.orderToPay}
                            accepter={Input}
                            min={1}
                            onChange={setOrderAmount}
                        />
                    </div> : null}
                </Form>

                {paymentPickupData.orderToPay && user.isPaymentControlEnabled && isChangeBuDefault ? <div>
                    <p className='mt-15'><strong>Контроль оплаты</strong> </p>
                    <div className="mt-15">
                        <Toggle checked={isPaymentControl} onChange={changePaymentControl} className="comparison-difference" />
                    </div>
                </div> : null}

                {showPayOnDelivery ? <div className="pay-method-info">
                    <p>{t('order.payMethod')}</p>
                </div> : null}

            </div>: null}

        </div>
    )
}
export default OrderProductsShipping