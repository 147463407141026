import React, {useEffect, useState} from "react";

import "./MyOrdersTable.sass"
import {Checkbox, Progress, Table, Tooltip, Whisper} from "rsuite";
import orderService from "../../services/orderService";
import GetOrderModel from "../../dto/order/GetOrderModel";
import {ValueType} from "rsuite/Checkbox";
import {NavLink} from "react-router-dom";
import ShippingAddress from "../../interfaces/ShippingAddress";
import {CarrierType} from "../../enums/CarrierType";
import NewPost from "../../assets/img/new_post_sm.png";
import Meest from "../../assets/img/meest_sm.png";
import Delivery from "../../assets/img/delivery_sm.png";
import Ukrpost from "../../assets/img/ukrpost_sm.png";
import PickUp from "../../assets/img/pickup.svg";
import GenericButton from "../Buttons/GenericButton";
import {useTranslation} from "react-i18next";
import OrderTypes from "../OrderTypes/OrderTypes";

const { Column, HeaderCell, Cell } = Table;

const CheckCell:React.FC<{ rowData?: {[key: string]: number}, onChange: any, checkedKeys: number[], dataKey: string}> = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
    <Cell {...props} style={{ padding: 0 }}>
        <div>
            <Checkbox
                value={rowData![dataKey]}
                inline
                onChange={onChange}
                checked={checkedKeys.some(item => item === rowData![dataKey])}
            />
        </div>
    </Cell>
);

const CellOrderId:React.FC<{ rowData?: {[key: string]: number},dataKey: string, orderType: string}> = (
    {rowData, dataKey, orderType, ...props}) => (
    <Cell {...props}>
        {orderType == 'reserve' ? <NavLink to={'/new-order/' + rowData![dataKey]} className={"name"}>{rowData![dataKey]}</NavLink> : <NavLink to={'/order/' + rowData![dataKey]} className={"name"}>{rowData![dataKey]}</NavLink>}

    </Cell>
)

const CellStatus:React.FC<{ rowData?: {[key: string]: number}, dataKey: string}> = (
    {rowData, dataKey,  ...props}) => (
    <Cell {...props}>
        <OrderTypes status={rowData![dataKey]} />
    </Cell>
)

const CellOrderInvoiceNumber:React.FC<{ rowData?: {[key: string]: number}, dataKey: string, tooltip: string}> = (
    {rowData, dataKey, tooltip,  ...props}) => (
    <Cell {...props}>
        {rowData![dataKey] ?
            <Whisper
                trigger="click"
                placement={'top'}
                speaker={
                    <Tooltip>{tooltip}</Tooltip>
                }
            >
            <div
                className="invoice_number_wrapper"
                onClick={() => navigator.clipboard.writeText((rowData![dataKey]).toString())}
            >{rowData![dataKey]}</div>
            </Whisper>
            : null}
    </Cell>
)

const CellDateTime:React.FC<{ rowData?: {[key: string]: number}, dataKey: string}> = (
    {rowData, dataKey,  ...props}) => (
    <Cell {...props}>
        <div className="date">{rowData![dataKey]}
        <span>{rowData!['deliveryTime']}</span>
        </div>
    </Cell>
)


const CellPrise:React.FC<{ rowData?: {[key: string]: number}, dataKey: string}> = ({rowData, dataKey, ...props}) => {
    const {t} = useTranslation();
    return (
        <Cell {...props}>
            <span>{rowData![dataKey]} {t('usd')}</span>
        </Cell>
    )
}

const CellDelivery:React.FC<{rowData?: {[key: string]: any}, dataKey: string}> = ({rowData, dataKey, ...props}) => {
    const address = rowData![dataKey] as ShippingAddress;
    return (
        <Cell {...props}>
            {address ? <div className="logo">
                {address.carrierType == CarrierType.NewPost ? <img src={NewPost} alt="NewPost" width="24" height="24" />  : null}
                {address.carrierType == CarrierType.MeestExpress ? <img src={Meest} alt="Meest" width="24" height="24" />  : null}
                {address.carrierType == CarrierType.Delivery ? <img src={Delivery} alt="Meest" width="24" height="24" />  : null}
                {address.carrierType == CarrierType.AddressDelivery ? <img src={PickUp} alt="Address Shipping" width="24" height="24" />  : null}
                {address.carrierType == CarrierType.PickUp ? <img src={PickUp} alt="PickUp" width="24" height="24" />  : null}
            </div> : null}

            {address?.state ? <span>{address.state}</span> : null }
            {address?.district ? <span>, {address.district}</span> : null }
            {address?.city ? <span>, {address.city}</span> : null }
            {address?.carrierBranch ? <span>, {address.carrierBranch}</span> : null }
            {address?.street ? <span>, {address.street}</span> : null }
            {address?.house ? <span>, {address.house}</span> : null }
            {address?.apartment ? <span>, {address.apartment}</span> : null }
            {rowData![dataKey]?.carModel ? <span>, {rowData![dataKey].carModel}</span> : null }
            {rowData![dataKey]?.carNumber ? <span>, {rowData![dataKey].carNumber}</span> : null }
            {rowData![dataKey]?.deliveryDate ? <span>, {rowData![dataKey].deliveryDate}</span> : null }
            {rowData![dataKey]?.deliveryTime ? <span>, {rowData![dataKey].deliveryTime}</span> : null }
        </Cell>
    )
}


const MyOrdersTable:React.FC<{data: GetOrderModel[],
    onOrderSelectionChange: (
    ids: number[]) => void,
    orderType: string,
    setButtonState?: (value: boolean) => void | null,
    deleteItem?: Function | null}> = (
    {data, onOrderSelectionChange, orderType, setButtonState, deleteItem}) => {

    const {t} = useTranslation();

    const [deviceHeight, setDeviceHeight] =  useState(window.innerHeight)

    useEffect(() => {
        let height = deviceHeight - 285
        setDeviceHeight(height)
    }, [])


    const [checkedKeys, setCheckedKeys] = useState<number[]>([]);
    let checked = false;
    let indeterminate = false;

    if (checkedKeys.length === data.length) {
        checked = true;

    } else if (checkedKeys.length === 0) {
        checked = false;
    } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
        indeterminate = true;
    }

    useEffect(() => {
        onOrderSelectionChange(checkedKeys);
        if (setButtonState ){
            if (checkedKeys.length != 0){
                setButtonState(false)
            }
            else {
                setButtonState(true)
            }
        }

    }, [checkedKeys])

    const handleCheckAll = (value: ValueType | undefined, checked: boolean) => {
        const keys = checked ? data.map(item => item.id) : [];
        setCheckedKeys(keys);

    };
    const handleCheck = (value:  ValueType | undefined, checked: boolean) => {
        const keys = checked ? [...checkedKeys, value as number] : checkedKeys.filter(item => item !== value);
        setCheckedKeys(keys);
    };
    return (
        <div className="my-orders-table-wrapper">
            <div className="table-responsive">
            <Table height={deviceHeight} data={data} id="table" wordWrap="break-word" >
                <Column width={50} align="center" verticalAlign="middle" >
                    <HeaderCell style={{ padding: 0 }}>
                        <div>
                            <Checkbox
                                inline
                                checked={checked}
                                indeterminate={indeterminate}
                                onChange={handleCheckAll}
                            />
                        </div>
                    </HeaderCell>
                    <CheckCell dataKey="id" checkedKeys={checkedKeys} onChange={handleCheck} />

                </Column>

                <Column width={110} verticalAlign="middle"  >
                    <HeaderCell>{t('tableHeader.orderId')}</HeaderCell>
                    <CellOrderId dataKey="id" orderType={orderType} />
                </Column>

                <Column flexGrow={1} verticalAlign="middle"  >
                    <HeaderCell>{t('tableHeader.orderDate')}</HeaderCell>
                    <CellDateTime dataKey="orderDate" />
                </Column>

                <Column flexGrow={1} verticalAlign="middle"  >
                    <HeaderCell>{t('tableHeader.status')}</HeaderCell>
                    <CellStatus dataKey="status" />
                </Column>

                <Column flexGrow={1} verticalAlign="middle"  >
                    <HeaderCell>{t('tableHeader.orderSum')}</HeaderCell>
                    <CellPrise dataKey="totalAmountUSD" />
                </Column>

                <Column flexGrow={1} verticalAlign="middle"  >
                    <HeaderCell>{t('tableHeader.income')}</HeaderCell>
                    <CellPrise dataKey="totalProfitUSD" />
                </Column>

                <Column flexGrow={3} verticalAlign="middle"  >
                    <HeaderCell>{t('tableHeader.delivery')}</HeaderCell>
                    <CellDelivery dataKey="deliveryAddress" />
                </Column>

                <Column width={165} verticalAlign="middle" resizable >
                    <HeaderCell>{t('tableHeader.deliveryId')}</HeaderCell>
                    <CellOrderInvoiceNumber dataKey="shippingInvoiceNumber" tooltip={t('orderSingle.copied')} />
                </Column>

            </Table>
            </div>
        </div>
    )
}

export default MyOrdersTable