import LoginPageModel from "../dto/login/LoginPageModel";
import ApiHttpHandler from "./apiHttpHandler";
import UserModel from "../dto/login/UserModel";
import AuthProvider from "./AuthProvider";
import store from "../store";
import SendCodeResponseModel from "../dto/login/SendCodeResponseModel";

const handler = new ApiHttpHandler();

const register = (firstName: string, lastName: string, email: string, password: string, confirmPassword: string) => {
    return handler.post("auth/registration", {
        "deviceId": "string",
        "devicePushToken": "string",
        "clientType": "web",
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "password": password,
        "confirmPassword": password,
        "logoUrl": "string",
        "trainerId": 0
    });
};

const login = (username: string, password: string, location: string): Promise<LoginPageModel> => {
    username = username.replace(/[\(\)\-\+ ]/g, '');
    return handler.post("auth/login", {
        "managerId": "0",
        "devicePushToken": "1111",
        "userType": 0,
        "login": username,
        "password": password,
        "location": location,
    })
        .then((response) => {

            if (response.access.accessToken) {
                AuthProvider.setAuthToken({token: response.access.accessToken, expiration: response.access.accessExpiresOn});
                AuthProvider.setRefreshToken({token: response.access.refreshToken, expiration: response.access.refreshExpiresOn});
                AuthProvider.updateUser(response.user);
            }

            return response;
        });
};

const sendCode = (entity: string, entityType: string, location: string): Promise<SendCodeResponseModel> => {
    entity = entity.replace(/[\(\)\-\+ ]/g, '');
    return  handler.post("auth/send-code", {
        "entity": entity,
        "entityType": entityType,
        "location": location
    })
        .then((response) => {
            return response
        })

}

const logout = () => {
    const user = store.getState().user.value
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    handler.post('auth/logout', {
        "managerId": user.managerId ?? user.id,
        "devicePushToken": "1111",
        "userType": user.roles.indexOf('Manager') == -1? 0: 1
    });
};

const refreshAuthToken = (token: string): Promise<LoginPageModel> => {
    const user = store.getState().user.value
    return handler.post('auth/refresh-token', {
        "managerId": user.managerId ?? user.id,
        "devicePushToken": "1111",
        "userType": user.roles.indexOf('Manager') == -1? 0: 1,
        "token": token
    });
}

export default {
    register,
    login,
    logout,
    sendCode,
    refreshAuthToken
};