import i18next from "i18next";
import {initReactI18next} from "react-i18next";

import translationPL from "./locales/pl/translation.json";
import translation from "./locales/ru/translation.json";
import translationUK from "./locales/uk/translation.json";

export const defaultNS = "translation";
export const resources = {
    ru: {
        translation: translation,
    },
    uk: {
        translation: translationUK
    },
    pl: {
        translation: translationPL
    }
} as const;

i18next.use(initReactI18next).init({
    lng: process.env.REACT_APP_LOCATION === 'pl' ? 'pl' : 'uk',
//    defaultNS,
    debug: true,
    resources
});
